.sidebar {
  flex-basis: 16.25rem;
  min-width: 16.25rem;
  @apply max-h-full p-7 bg-white shadow-light z-30;
}

.logo-sidebar {
  height: 3.1875rem;
}

.select-sidebar-projects {
  @apply mt-8 mb-9;
}

.sidebar-menu {
  @apply pt-1 -ml-2 text-dark-grey-300;
}

.sidebar-list {
  @apply mb-7 mt-10;
}

.gsif.sidebar-chevron {
  @apply text-10;
}

.sidebar-accordion {
  @apply mb-3 ml-2;
}

.sidebar-item {
  @apply mb-7 text-dark-grey;
}

.sidebar-item .gsif {
  @apply mr-2 text-grey;
}

.sidebar-item .gsif.accordion-chevron {
  @apply text-dark-grey;
}

.sidebar-accordion .sidebar-item {
  @apply mb-4;
}

.sidebar-accordion .accordion-content {
  @apply ml-4;
}

.sidebar-link {
  @apply pl-2;
}

.sidebar-link.active {
  @apply bg-blue-200 rounded-md py-1.5 px-2 text-blue;
}

.sidebar-link.active i.gsif {
  @apply text-blue;
}
