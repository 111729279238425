:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-family: 'PingFang TC', 'Microsoft JhengHei', 'Microsoft YaHei',
    'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
small,
em,
strong,
ul,
li,
figure {
  margin: 0;
  padding: 0;
}

button {
  outline: none;
  cursor: pointer;
}

hr {
  border-top: solid 1px #ccc;
}

// Custom style
.text-icon-align-center {
  display: flex;
  align-items: center;
}

// react-tooltip custom style
.tooltip-date,
.tooltip-hint,
.tooltip-btn {
  max-width: px2rem(192) !important;
  padding: $spacing-xs $spacing-s !important;
  font-size: px2rem(14) !important;
  line-height: px2rem(22) !important;
  word-break: break-all;
}

.escape-prefix::after {
  content: '{{';
}

.escape-suffix::after {
  content: '}}';
}

/**
* Animate.css
**/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.animate__fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
.animate__fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
