@import '../../assets/styles/addon/main';

.confirm-modal {
  padding: px2rem(45) px2rem(38) px2rem(23);
  text-align: center;

  .confirm-modal-title {
    font-size: px2rem(24);
    font-weight: 600;
    line-height: px2rem(33);
    margin-bottom: px2rem(18);
    color: $color-blue;
  }

  .confirm-modal-content {
    font-size: px2rem(15);
    line-height: px2rem(22);
    min-height: px2rem(65);
    margin-bottom: px2rem(18);
    color: $color-dark-grey;
  }

  .confirm-modal-btn-wrapper {
    display: flex;
    justify-content: center;

    .confirm-modal-btn {
      min-width: px2rem(120);

      & + .confirm-modal-btn {
        margin-left: px2rem(52);
      }
    }
  }
}

// confirm modal z-index

#confirm {
  z-index: 50;
}
