@import './assets/styles/tailwind/index.css';
@import-normalize;

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: PingFang, Arial, 'Microsoft JhengHei', '微軟正黑體', Helvetica,
    sans-serif;
}

.select .m-select__indicators {
  display: none;
}

.select .m-select__indicators .m-select__indicator-separator {
  display: none;
}
