.modal {
  @apply h-full	w-full fixed top-0 left-0 bg-black bg-opacity-50;
}

.modal .modal-center {
  min-height: calc(100% - 1.75rem * 2);
  max-width: 90vw;
  @apply flex items-center justify-center w-120 my-7 mx-auto;
}

.modal .modal-content {
  @apply w-full bg-grey-300;
  @apply border border-black border-opacity-20 rounded;
}

.modal .modal-content.modal-content-pulse {
  animation: modalPulse 0.1s;
}

@keyframes modalPulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
