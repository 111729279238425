.navbar {
  @apply flex items-center bg-white shadow-light w-full;
  @apply sticky right-0 top-0 z-30;
  height: 4.375rem;
}

.logo-without-sidebar {
  @apply hidden xl:flex justify-center;
  flex-basis: 16.25rem;
  max-width: 16.25rem;
}

.btn-navbar-user {
  @apply flex items-center p-2 pr-3 outline-none;
  @apply hover:bg-blue-200;
  @apply transition-colors duration-200;
  @apply border-none rounded-3xl;
}
