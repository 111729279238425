.loading-full-screen {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;

  &.fade-out {
    opacity: 0;
    width: 0;
    height: 0;
    transition: width 0.2s 0.2s, height 0.2s 0.2s, opacity 0.2s;
  }
  &.fade-in {
    opacity: 1;
    height: 100%;
    width: 100%;
    transition: width 0s, height 0s, opacity 0s 0s;
  }
}
