@font-face {
  font-family: 'GoSky';
  src: url('../../fonts/GoSky/GoSky.eot?n41mke');
  src: url('../../fonts/GoSky/GoSky.eot?n41mke#iefix')
      format('embedded-opentype'),
    url('../../fonts/GoSky/GoSky.ttf?n41mke') format('truetype'),
    url('../../fonts/GoSky/GoSky.woff?n41mke') format('woff'),
    url('../../fonts/GoSky/GoSky.svg?n41mke#GoSky') format('svg');
}

i.gsif {
  font-family: 'GoSky';
  display: inline-block;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code  */
.gsif-floppy-disk:before {
  content: '\e92a';
}
.gsif-qrcode:before {
  content: '\e928';
}
.gsif-badge:before {
  content: '\e929';
}
.gsif-chevron-right:before {
  content: '\e926';
}
.gsif-chevron-down:before {
  content: '\e927';
}
.gsif-tag:before {
  content: '\e925';
}
.gsif-records:before {
  content: '\e923';
}
.gsif-store:before {
  content: '\e922';
}
.gsif-link:before {
  content: '\e921';
}
.gsif-exclamation:before {
  content: '\e920';
}
.gsif-fb-logo-circle:before {
  content: '\e91f';
}
.gsif-times-radius:before {
  content: '\e91d';
}
.gsif-external-link:before {
  content: '\e91e';
}
.gsif-sort:before {
  content: '\e907';
}
.gsif-triangle:before {
  content: '\e91b';
}
.gsif-arrow-left:before {
  content: '\e91c';
}
.gsif-trash:before {
  content: '\e905';
}
.gsif-plus-circle:before {
  content: '\e906';
}
.gsif-arrow-right:before {
  content: '\e908';
}
.gsif-check:before {
  content: '\e909';
}
.gsif-copy:before {
  content: '\e90a';
}
.gsif-dashboard:before {
  content: '\e90b';
}
.gsif-minus-circle:before {
  content: '\e90c';
}
.gsif-star-circle:before {
  content: '\e90d';
}
.gsif-pen:before {
  content: '\e90e';
}
.gsif-ellipsis-h:before {
  content: '\e90f';
}
.gsif-arrow-down-circle:before {
  content: '\e910';
}
.gsif-arrow-up-circle:before {
  content: '\e911';
}
.gsif-people:before {
  content: '\e912';
}
.gsif-question-circle:before {
  content: '\e913';
}
.gsif-gift-card:before {
  content: '\e914';
}
.gsif-redo:before {
  content: '\e915';
}
.gsif-search:before {
  content: '\e916';
}
.gsif-cog:before {
  content: '\e917';
}
.gsif-cloud-upload:before {
  content: '\e918';
}
.gsif-exclamation-triangle:before {
  content: '\e919';
}
.gsif-times:before {
  content: '\e91a';
}
.gsif-point-circle-fill:before {
  content: '\e904';
}
.gsif-point-circle:before {
  content: '\e902';
}
.gsif-heart-circle:before {
  content: '\e903';
}
.gsif-gift:before {
  content: '\e901';
}
.gsif-clock:before {
  content: '\e900';
}
.gsif-send:before {
  content: '\e924';
}
.gsif-download:before {
  content: '\e92b';
}
.gsif-filter:before {
  content: '\e92c';
}
